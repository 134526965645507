import { Box } from "@mui/material";
import "./App.css";
import Header from "./components/Header";
import Hero from "./components/Hero";
import WalletProvider from "./WalletContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <WalletProvider>
      <Box sx={{ m: "auto", maxWidth: "2000px" }}>
        <Header />
        <Hero />
      </Box>
      <ToastContainer />
    </WalletProvider>
  );
}

export default App;
