import React, { createContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import ChainEx from "@chainex/chainex-sdk";
import { ParticleNetwork, WalletEntryPosition } from "@particle-network/auth";
import { ParticleProvider } from "@particle-network/provider";
import { EthereumGoerli } from "@particle-network/chains";
import { toast } from "react-toastify";
import { NFTStorage, File } from "nft.storage";
import { ABI } from "./abi";
// The 'mime' npm package helps us set the correct file type on our File objects
import mime from "mime";

// The 'path' module provides helpers for manipulating filesystem paths
import path from "path";
// Create the context
export const WalletContext = createContext();

const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [signer, setSigner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isBatchMint, setIsBatchMint] = useState(true);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [externalLink, setExternalLink] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [client, setClient] = useState(null);
  const [imageFiles, setImageFiles] = useState();
  const [isMinting, setIsMinting] = useState(false);
  const [txHash, setTxHash] = useState(null);
  const intializingSDK = async () => {
    setLoading(true);
    try {
      const chain =
        "0xGS-12c04e82bec385ab1019d8ad3e506ffdbdf4627ce34843a090fbc3d02da85506";

      const particle = new ParticleNetwork({
        projectId: process.env.REACT_APP_PROJECT_ID,
        clientKey: process.env.REACT_APP_CLIENT_KEY,
        appId: process.env.REACT_APP_APP_ID,
        chainName: EthereumGoerli.name, //optional: current chain name, default Ethereum.
        chainId: EthereumGoerli.id, //optional: current chain id, default 1.
        wallet: {
          //optional: by default, the wallet entry is displayed in the bottom right corner of the webpage.
          displayWalletEntry: true, //show wallet entry when connect particle.
          defaultWalletEntryPosition: WalletEntryPosition.BR, //wallet entry position
          uiMode: "dark", //optional: light or dark, if not set, the default is the same as web auth.
          supportChains: [{ id: EthereumGoerli.id, name: EthereumGoerli.name }], // optional: web wallet support chains.
          customStyle: {}, //optional: custom wallet style
        },
        securityAccount: {
          //optional: particle security account config
          //prompt set payment password. 0: None, 1: Once(default), 2: Always
          promptSettingWhenSign: 1,
          //prompt set master password. 0: None(default), 1: Once, 2: Always
          promptMasterPasswordSettingWhenLogin: 1,
        },
      });
      const particleProvider = new ParticleProvider(particle.auth);
      const ethersProvider = new ethers.providers.Web3Provider(
        particleProvider
      );
      console.log("Ethers Provider", ethersProvider);

      const chainEx = new ChainEx(chain);
      setClient(chainEx);
      const options = {
        chainId: 5, // Required: Replace with the desired chain ID
        rpcUrl: "https://rpc.ankr.com/eth_goerli", // Optional: Specify your custom RPC URL if needed
        isSponsored: true,
      };
      const smartAccount = await chainEx.initalize(ethersProvider, options);
      console.log("Smart Account", smartAccount);
      setWalletAddress(smartAccount.smartAccountAddress);
      toast.success("Wallet Connected successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setLoading(false);
    } catch (err) {
      toast.error("Error while connecting wallet", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setLoading(false);
      console.log("Error", err);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setImageFiles(img);
      setSelectedImage(URL.createObjectURL(img));
    }
  };

  useEffect(() => {
    if (!walletAddress) {
      //intializingSDK();
    }
  }, []);

  // Connect wallet function
  const connectWallet = async () => {
    if (!walletAddress) {
      intializingSDK();
    }
  };

  const mint = async () => {
    if (
      !walletAddress ||
      !selectedImage ||
      !name ||
      !description ||
      !externalLink
    ) {
      toast.error("Please fill all the details", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      setIsMinting(true);
      console.log(
        walletAddress,

        selectedImage,
        name,
        description,
        externalLink,
        isBatchMint
      );
      const id = toast.loading("Minting NFT...", {
        position: toast.POSITION.BOTTOM_LEFT,
        isLoading: true,
      });
      try {
        const type = mime.getType(imageFiles);
        console.log(imageFiles, path.basename(imageFiles), type);
        const imageFile = new File([imageFiles], imageFiles.name, {
          type,
        });

        console.log("Image File", imageFile);
        const imageBlob = imageFile.slice(0, imageFile.size, imageFile.type);
        console.log("Image File 2", imageBlob);
        const nftstorage = new NFTStorage({
          token: process.env.REACT_APP_NFT_STORAGE_KEY,
        });

        toast.update(id, {
          render: "Uploading to IPFS..",
          position: toast.POSITION.BOTTOM_LEFT,
          isLoading: true,
        });
        const nft = await nftstorage.store({
          image: imageFile,
          name,
          description,
          external_url: externalLink,
        });
        console.log("NFT", nft);
        toast.update(id, {
          render: "Pushing User Op..",
          position: toast.POSITION.BOTTOM_LEFT,
          isLoading: true,
        });
        const erc721Contract = new ethers.Contract(
          "0x6F755C011Fd1c03F7fb91b4f70974deC88206E2C",
          ABI
        );

        const uri = `ipfs://${nft.ipnft}/metadata.json`;

        const trx = {
          target: "0x6F755C011Fd1c03F7fb91b4f70974deC88206E2C",
          data: erc721Contract.interface.encodeFunctionData("mint", [
            nft.ipnft,
          ]),
        };

        if (isBatchMint) {
          const transactions = [trx, trx];
          const userOpHash = await client.sendUserOpsBatch(transactions);
          console.log("userOpHash", userOpHash);
          toast.update(id, {
            render: "User Op pushed, waiting for confirmation..",
            position: toast.POSITION.BOTTOM_LEFT,
            isLoading: true,
          });
          //const receipt = await client.getTrxReceiptByUserOpHash(userOpHash, 5);

          setTxHash(userOpHash.userOpHash);
          toast.update(id, {
            type: toast.TYPE.SUCCESS,
            render: "NFT minted successfully",
            position: toast.POSITION.BOTTOM_LEFT,
            isLoading: false,
            autoClose: 5000,
          });
        } else {
          const userOpHash = await client.sendUserOp(trx);
          console.log("userOpHash", userOpHash.userOpHash);

          toast.update(id, {
            render: "User Op pushed, waiting for confirmation..",
            position: toast.POSITION.BOTTOM_LEFT,
            isLoading: true,
          });
          //const receipt = await client.getTrxReceiptByUserOpHash(userOpHash, 5);
          setTxHash(userOpHash.userOpHash);
          toast.update(id, {
            type: toast.TYPE.SUCCESS,
            render: "NFT minted successfully",
            position: toast.POSITION.BOTTOM_LEFT,
            isLoading: false,
            autoClose: 5000,
          });
        }
        //setIsMinting(false);
      } catch (e) {
        console.log(e);
        setIsMinting(false);
        toast.update(id, {
          type: toast.TYPE.ERROR,
          render: "Something went wrong",
          position: toast.POSITION.BOTTOM_LEFT,
          isLoading: false,
          autoClose: 5000,
        });
      }
    }
  };

  return (
    <WalletContext.Provider
      value={{
        walletAddress,
        signer,
        connectWallet,
        loading,
        selectedImage,
        handleImageChange,
        isBatchMint,
        setIsBatchMint,
        setName,
        setDescription,
        setExternalLink,
        mint,
        isMinting,
        txHash,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
