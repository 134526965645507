import { Box, Button, IconButton } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { WalletContext } from "../WalletContext";
import chainex from "../assets/chainex.svg";
import truncateEthAddress from "truncate-eth-address";
import { CopyAll } from "@mui/icons-material";
import { toast } from "react-toastify";
const Header = () => {
  const { walletAddress, signer, connectWallet, loading } =
    useContext(WalletContext);

  return (
    <Box
      sx={{
        bgcolor: "#191919",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "space-between",
        p: "1.5rem 1rem",
      }}
    >
      <Box
        sx={{
          fontSize: { xs: "24px", md: "48px" },
          fontWeight: "bold",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img src={chainex} style={{ height: "50px", marginRight: "8px" }} />
        ArtEx
      </Box>
      <Box>
        <Button
          sx={{
            bgcolor: "#A100ED",
            color: "white",
            p: "0.5rem 2rem",
            marginTop: { xs: "10px", md: "0" },
          }}
          onClick={() => {
            if (!walletAddress) {
              connectWallet();
            }
          }}
        >
          {loading
            ? "Initializing smart account..."
            : walletAddress
            ? `Smart Account: ${truncateEthAddress(walletAddress)}`
            : "Connect Wallet"}
        </Button>
        {walletAddress && (
          <IconButton
            sx={{ bgcolor: "#A100ED", color: "white", marginLeft: "1rem" }}
            onClick={() => {
              if (walletAddress) {
                navigator.clipboard.writeText(walletAddress);
                toast.success("Smart account address copied", {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
              }
            }}
          >
            <CopyAll />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default Header;
