import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  TextareaAutosize,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useContext, useRef } from "react";
import bgimg from "../assets/bgimg.svg";
import imgIcon from "../assets/imgIcon.svg";
import exclamation from "../assets/exclamation.svg";
import upload from "../assets/upload.svg";
import InputField from "./reusableComponent/InputField";
import WhiteButton from "./reusableComponent/WhiteButton";
import { WalletContext } from "../WalletContext";

const Hero = () => {
  const {
    walletAddress,
    selectedImage,
    handleImageChange,
    isBatchMint,
    setIsBatchMint,
    setName,
    setDescription,
    setExternalLink,
    mint,
    isMinting,
    txHash,
  } = useContext(WalletContext);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const hiddenFileInput = useRef(null);
  return (
    <Box
      sx={{
        p: "3rem 1rem",
        m: { xs: "1rem", md: "3rem 2rem" },
        borderRadius: "20px",
        height: "fit-content",
        backgroundImage: `url(${bgimg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "start", md: "center" },
          justifyContent: "space-between",
          width: "95%",
        }}
      >
        <Box>
          <Box sx={{ display: { md: "flex" }, alignItems: "center" }}>
            <Box
              sx={{ fontSize: { xs: "28px", md: "38px" }, fontWeight: "bold" }}
            >
              Create Your NFT
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: "#2C2C2C",
              display: "flex",
              alignItems: "center",
              p: "5px",
              m: "20px 0",
            }}
          >
            <img src={exclamation} alt="" />
            <Box
              sx={{
                fontSize: { xs: "12px", md: "24px" },
                margin: "5px 10px",
                width: { xs: "100%", sm: "100%" },
              }}
            >
              This demo showcase account abstraction by gaslesss NFT minting
              using ChainEx SDK.
            </Box>
          </Box>
        </Box>
        <ImageListItem
          sx={{ width: { xs: "4rem", sm: "6rem", md: "16rem", lg: "20rem" } }}
        >
          <img src={imgIcon} alt="Image" />
        </ImageListItem>
      </Box>
      <Box
        onClick={!selectedImage ? handleClick : null}
        sx={{
          display: "flex",
          justifyContent: "center",
          p: "3rem 0",
          cursor: "pointer",
        }}
      >
        {selectedImage ? (
          <img src={selectedImage} width={"60%"} alt="Selected" />
        ) : (
          <Box
            sx={{
              p: "5rem",
              bgcolor: "#1D1D1D",
              boxShadow: "inset 0 0 5px 2px rgba(80, 80, 80)",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ m: "5px 0" }}>
              <img src={upload} alt="" />
            </Box>

            <Box
              sx={{
                fontSize: { xs: "24px", md: "28px" },
                fontWeight: "bold",
                m: "5px 0",
              }}
            ></Box>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <Box
              sx={{
                fontSize: { xs: "18px", md: "24px" },
                color: "#2E66BB",
                m: "5px 0",
              }}
            >
              Browse files
            </Box>
            <Box
              sx={{
                fontSize: { xs: "18px", md: "24px" },
                color: "rgb(250, 250, 250, 0.6)",
                m: "5px 0",
                textAlign: "center",
              }}
            >
              JPG, PNG, GIF, SVG
            </Box>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          width: "95%",
          bgcolor: "#1D1D1D",
          boxShadow: "inset 0 0 5px 2px rgba(80, 80, 80)",
        }}
      >
        <Box sx={{ p: "2rem" }}>
          <InputField
            label="Name Your NFT*"
            placeholder="ChainEx"
            onChange={(e) => setName(e.target.value)}
          />

          <Box sx={{ m: "1rem 0 0 0" }}>
            <Box
              sx={{
                m: "0.8rem 0",
                color: "white",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Description*
            </Box>
            <Box
              sx={{
                display: "flex",
                borderRadius: "5px",
                border: "1px solid #626262",
                color: "white",
                height: "91px",
                padding: "0 0 0 0.5rem",
                outline: "none",
                backgroundColor: "#2A2A2A",
                fontSize: "24px",
              }}
            >
              <textarea
                style={{
                  width: "100%",
                  // borderRadius: "5px",
                  border: "none",
                  outline: "none",
                  color: "white",
                  backgroundColor: "transparent",
                  fontSize: "18px",
                  padding: "1rem 0",
                }}
                placeholder="Describe Your NFT"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
            <Box
              sx={{ color: "rgb(255, 49, 49)", m: "5px 0", height: "1.2rem" }}
            >
              {/* {!validity && <div>{alertMessage}</div>} */}
            </Box>
          </Box>
          <InputField
            label="External Link*"
            placeholder="http//collections.com"
            onChange={(e) => setExternalLink(e.target.value)}
          />
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              style={{ color: "white", fontSize: "16px", fontWeight: "bold" }}
            >
              Batch Mint
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={isBatchMint}
              onChange={(e) => setIsBatchMint(e.target.value)}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label="Yes"
              />

              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "center", p: "2rem 0" }}>
            <Button
              onClick={!isMinting ? mint : null}
              disabled={isMinting}
              sx={{
                bgcolor: "white",
                color: "black",
                p: "0.5rem 2rem",
                height: "3rem",
                borderRadius: "10px",
                width: "20rem",
              }}
            >
              {"Mint NFT"}
            </Button>
            {txHash && (
              <Button
                onClick={() =>
                  window.open(
                    `https://jiffyscan.xyz/userOpHash/${txHash}?network=goerli`
                  )
                }
                disabled={!isMinting}
                sx={{
                  marginLeft: "1rem",
                  bgcolor: "purple",
                  color: "white",
                  p: "0.5rem 2rem",
                  height: "3rem",
                  borderRadius: "10px",
                  width: "20rem",
                }}
              >
                {"View NFT"}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
