import { Box, Button, Input, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const InputField = ({ placeholder, label, onChange, validity, ...restProps }) => {

  return (
    <Box sx={{ m: "1rem 0 0 0" }}>
      <Box
        sx={{
          m: "0.8rem 0",
          color: "white",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {label}
      </Box>
      <Box sx={{ display: "flex",
          borderRadius: "5px",
          border: "1px solid #626262",
          color: "white",
          height: "91px",
          padding: "0 0 0 0.5rem",
          outline: "none",
          backgroundColor: "#2A2A2A",
          fontSize: "24px",}}>
      <input
      
      onChange={onChange}
      {...restProps}
        style={{
          width: "100%",
          // borderRadius: "5px",
          border: "none",
          outline: 'none',
          color: "white",
          backgroundColor: "transparent",
          fontSize: "18px",
        }}
        placeholder={placeholder}
      />
      </Box>
      <Box sx={{ color: 'rgb(255, 49, 49)', m: '5px 0', height: '1.2rem' }}>
      {/* {!validity && <div>{alertMessage}</div>} */}
      </Box>
    </Box>
  );
};

export default InputField;
